import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, FormSelect, FormGroup, FormControl, Placeholder, FormCheck } from "react-bootstrap";
import { defineMessages } from "react-intl";
import PropTypes from 'prop-types';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@fortawesome/pro-regular-svg-icons";
import isEmpty from "lodash.isempty";

//services
import { userRequestHeaders, userGetLocale } from "../../../../services/user";
import { mapLocaleToBCP47, validate, isFormEmpty } from "../../../../services/helpers";

import AlertDialog from "../../../../components/AlertDialog";
import TextInputWithAttachments from "../../../../components/TextInputWithAttachments";


// strings for translations
const messages = defineMessages({
    backToInvoice: {
        id: "Palaa laskun tietoihin",
        defaultMessage: "Palaa laskun tietoihin"
    },
    title: {
        id: "Aloita uusi keskustelu",
        defaultMessage: "Aloita uusi keskustelu"
    },
    chooseTopicLabel: {
        id: "Mitä asiasi koskee?",
        defaultMessage: "Mitä asiasi koskee?"
    },
    chooseTopic: {
        id: "Valitse aihe",
        defaultMessage: "Valitse aihe"
    },
    send: {
        id: "Lähetä",
        defaultMessage: "Lähetä"
    },
    authError: {
        id: 'Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.',
        defaultMessage: 'Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.'
    },
    notFound: {
        id: "Yhteydenottolomakkeita ei ole saatavilla juuri nyt. Yritä myöhemmin uudelleen.",
        defaultMessage: "Yhteydenottolomakkeita ei ole saatavilla juuri nyt. Yritä myöhemmin uudelleen."
    },
    errorOccurredTryAgain: {
        id: "Tapahtui virhe. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tapahtui virhe. Ole hyvä ja yritä uudelleen."
    },
    cancel: {
        id: "Peruuta",
        defaultMessage: "Peruuta"
    }
});

export { messages };

const MessageCenterModal = (props) => {
    const lng = props.intl.formatMessage;
    const formRef = useRef(null);
    const [forms, setForms] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        message: '',
        type: ''
    });
    const [formFields, setFormFields] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [attachments, setAttachments] = useState([]);
    const [loadingFormContent, setLoadingFormContent] = useState(false);
    const [confirmClose, setConfirmClose] = useState(false);
    const [attachmentsEnabled, setAttachmentsEnabled] = useState(false);
    let exludedElementIds = ["message-center-form-option"]; // changes in the dropdown value do not need to be checked when closing the form
    const count = 5; // number of placeholder skeletons
    const backendRequirements = ['request_type_id', 'summary', 'description', 'notification_email', 'job_id', 'dropdown_value']; // backend requires these keys to work
    
    // Attachment configurations
    const acceptedFileTypes = '.jpg,.jpeg,.png,.svg,.tif,.tiff,.gif,.bmp,.docx,.doc,.odt,.xlsx,.xls,.ods,.pptx,.ppt,.odp,.pdf,.txt,.csv';
    const acceptedFileSize = 30;
    const byteConversionFactor = 1024 * 1024;
    const sizeUnit = 'MB';
    const acceptedFileAmount = 10;
    
    useEffect(() => {
        // get available message center forms
        if (forms.length === 0) {
            getForms();
        }

    }, []);

    useEffect(() => {
        if (props.alert.show && props.alert.message !== "" && props.alert.type !== "") {
            showAlerts(props.alert);
        } else {
            clearAlerts();
        }

    }, [props.alert]);

    useEffect(() => {
        if (props.clearMessageCenterModal) {
            resetValuesAndClose();
        }

    }, [props.clearMessageCenterModal]);

    /**
     * Get available message center form templates
     */
    function getForms() {
        const headers = userRequestHeaders();
        let formOptions = [];

        // clear any error messages
        clearAlerts();

        // get available message center forms
        axios({
            method: 'GET',
            url: process.env.REACT_APP_API_URL + '/online/messagecenter/forms?language=' + mapLocaleToBCP47(userGetLocale()),
            headers: headers
        }).then(function (response) {
            formOptions = response.data.form;
            setForms(formOptions);
        }).catch(function (error) {
            if (error.response?.status === 401) {
                // session expired, log user out
                const alert = { message: lng(messages.authError), type: 'danger' };
                props.history.push({
                    pathname: "/logout",
                    alert
                });
            } else if (error.response?.status === 404) {
                showAlerts({
                    message: lng(messages.notFound),
                    type: 'warning'
                });
            } else {
                showAlerts({
                    message: lng(messages.errorOccurredTryAgain),
                    type: 'danger'
                });
            }
        });
    }

    /**
     * Get contents/fields of a single form template.
     */
    function getFormContents(formId) {
        // clear any error messages
        clearAlerts();

        if (formId !== '') {
            // add job and form id for later use 
            setInputValues({ ...inputValues, 'job_id': props.jobid, 'request_type_id': formId });
            // show skeleton placeholders while getting content
            setLoadingFormContent(true);

            const headers = userRequestHeaders();

            // get contents/fields of a message center form template
            axios({
                method: 'GET',
                url: process.env.REACT_APP_API_URL + '/online/messagecenter/form?formid=' + formId + '&language=' + mapLocaleToBCP47(userGetLocale()),
                headers: headers
            }).then(function (response) {
                // check if response data needs to be modified and set form fields
                setFormFields(modifyResponseData(response.data.fields));

                // toggle skeletons off
                setLoadingFormContent(false);
            }).catch(function (error) {
                // toggle skeletons off
                setLoadingFormContent(false);

                if (error?.response?.status === 401) {
                    // session expired, log user out
                    const alert = { message: lng(messages.authError), type: 'danger' };
                    props.history.push({
                        pathname: "/logout",
                        alert
                    });
                } else if (error?.response?.status === 404) {
                    // forms not found
                    showAlerts({
                        message: lng(messages.notFound),
                        type: 'warning'
                    });
                } else {
                    showAlerts({
                        message: lng(messages.errorOccurredTryAgain),
                        type: 'danger'
                    });
                }
            });
        } else {
            setFormFields([]);
            setAttachmentsEnabled(false);
        }
    }

    /**
     * Set alert message and type, and show alertDialog.
     *
     * @param {*} alert
     */
    function showAlerts(alert) {
        setAlert({
            message: alert.message,
            type: alert.type
        });

        setShowAlert(true);
    }

    /**
     * Clear error messages and hide alertDialog.
     */
    function clearAlerts() {
        setAlert({
            message: '',
            type: ''
        });

        setShowAlert(false);
    }

    /**
     * Check for modification needs in data, e.g. change customfields field_id's to meet backend requirements.
     *
     * @param {*} data
     */
    function modifyResponseData(data) {
        let modifiedData = data;

        // loop data to check if any changes to fields should be done
        modifiedData.forEach((item, index, object) => {
            if (item.field_id === 'customfield_14103') {
                item.field_id = 'notification_email'; // customfield_14103 -> notification_email
            } else if (item.field_id === 'attachment') {
                // Remove attachment field and enable it as part of the description component
                object.splice(index, 1);
                setAttachmentsEnabled(true);
            }
        });

        return modifiedData;
    }


    /**
     * Handle changes in input and remove errors.
     *
     * @param {*} e
     */
    function handleInputs(e) {
        // remove validation errors
        if (e.target.checkValidity()) {
            e.target.classList.remove("is-invalid");
        }

        setInputValues({ ...inputValues, [e.target.id]: e.target.value });
    }
    
    /**
     * Handle attachments and set them to state.
     * @param attachments
     */
    function handleAttachments(attachments) {
        setAttachments(attachments);
    }

    /**
     * Check the form before closing to see if it is empty or not.
     *
     * Display a confirmation, if the form is not empty (message will be discarded).
     * Close the form without additional confirmations, if the form is empty.
     */
    function handleHide() {

        if (!isFormEmpty(formRef.current, exludedElementIds)) {
            // show confirmation to user
            setConfirmClose(true);
        } else {
            // clear form values and close the modal
            resetValuesAndClose();
        }
    }

    /**
     * Hide confirmation and allow the user to keep writing, or discard message and
     * hide modal.
     *
     * @param {*} bool Hide modal
     */
    function handleCloseConfirmation(bool) {
        // hide modal if user has confirmed it
        if (bool) {
            // clear dropdown values and close the modal
            resetValuesAndClose();
        } else {
            // hide confirmation to keep writing
            setConfirmClose(false);
        }
    }

    /**
     * Map data for submit purposes.
     * 
     * If the form contains fields that do not meet the backend requirements, 
     * add the content of those fields to 'description'.
     * 
     * If form has a input with options, add that option value to dropdown_value (customfield_14001),
     * otherwise it must be kept null.
     * 
     *  
     * @param {*} data 
     */
    function mapDataForSubmit(data) {
        let requestData = {
            request_type_id: data.request_type_id,
            summary: data.summary,
            description: data.description,
            notification_email: data.notification_email,
            job_id: data.job_id,
            dropdown_value: null // dropdown_value only exists when there is a form with multiple options field
        };

        // go through each field
        for (const [key, value] of Object.entries(data)) {
            if (key === 'customfield_14001') {
                // add value for dropdown_value (customfield_14001) if it exists
                requestData.dropdown_value = value;
            } else if (!backendRequirements.includes(key)) {
                const label = document.querySelector("label[for=" + key +"]").innerText ?? key;
                // if there are fields that don't meet the backend requirement, add user input to description 
                requestData.description += " \n" + label + ": " + value;
            }
        }
        
        let objectIds = [];
        
        // Add object_ids for attachments
        for (const attachment of attachments) {
            if (typeof attachment.objectid !== 'undefined' && attachment.objectid !== '') {
                objectIds.push(attachment.objectid);
            }
        }
        requestData.object_ids = objectIds;

        // return request data that is ready for submit
        return requestData;
    }

    /**
     * Handle submitting the form. Check that form passes validation 
     * and then prepare the data for submit.
     *
     * @param {*} e
     */
    function handleSubmit(e) {
        e.preventDefault();

        if (validate(e.currentTarget)) {
            // prepare data for submit
            const data = mapDataForSubmit(inputValues);
            props.handleSubmit(data);
        }
    }

    /**
     * Reset form values and close the modal
     */
    function resetValuesAndClose() {
        // clear alerts, dropdown and input values
        clearAlerts();
        setFormFields([]);
        setInputValues({});
        setAttachmentsEnabled(false);

        // ensure close confirmation if hidden and close the modal
        setConfirmClose(false);
        props.hide();
    }


    return (
        <Modal id="message-center-modal" show={props.show} dialogClassName="message-center-form" onHide={handleHide} aria-labelledby="modal-title-message-center-forms" size={'lg'} backdrop="static">
            <div className="form-modal-parent">
                <div className={confirmClose ? "offcanvas-modal offcanvas offcanvas-start show " : ""}>
                    {confirmClose && (
                        <div>
                            <h3>Close the form</h3>
                            <p>
                                There are changes in the form. Are you sure you want to return to the invoice?
                            </p>
                            <p>
                                Your message will not be sent and all the changes in the form will be discarded jada jada jada.
                            </p>
                            <div className="mt-4">
                                <h5>Are you sure you want to close the form and discard the message?</h5>
                            </div>
                            <div className="d-grid d-sm-block mt-3">
                                <Button id="close-confirmation-close" onClick={() => handleCloseConfirmation(true)} >Close and discard message</Button>
                                <Button id="close-confirmation-cancel" onClick={() => handleCloseConfirmation(false)} variant="tertiary">Keep writing</Button>
                            </div>
                        </div>
                    )}
                </div>

                <Modal.Header closeButton>
                    <Button className="modal-back" onClick={handleHide}>
                        <FontAwesomeIcon icon={far.faArrowLeft} /> {lng(messages.backToInvoice)}
                    </Button>
                    <Modal.Title as="h4" id="modal-title-message-center-forms">
                        {lng(messages.title)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showAlert && (
                        <AlertDialog
                            message={alert.message}
                            type={alert.type}
                        />
                    )}
                    <Form id="message-center-form" onSubmit={handleSubmit} ref={formRef} noValidate>
                        {/* List all available forms */}
                        {
                            forms.length > 0 && (
                                <FormGroup className="form-floating">
                                    <FormSelect id="message-center-form-option" role="select" className="form-control mb-3" onChange={(e) => getFormContents(e.target.value)} placeholder={lng(messages.chooseTopicLabel)}  >
                                        <option id={lng(messages.chooseTopic)} key={lng(messages.chooseTopic)} value="" >{lng(messages.chooseTopic)}</option>
                                        {forms.map((option) => {
                                            return (
                                                <option id={option.form_id} key={option.form_id} value={option.form_id} >{option.form_name}</option>
                                            )
                                        })}
                                    </FormSelect>
                                    <Form.Label htmlFor='message-center-form-option'>{lng(messages.chooseTopicLabel)}</Form.Label>
                                    <Form.Control.Feedback type="invalid" />
                                </FormGroup>
                            )
                        }
                        {loadingFormContent ? (
                            <div>
                                {/* show skeleton placeholders while loading */}
                                {Array(count).fill(null).map((value, index) => (
                                    <Placeholder as={FormGroup} animation="glow" className="mb-3" style={{ opacity: 1 / (index + 1) }} key={"skeleton-input-" + index}>
                                        <Placeholder as={FormControl} ></Placeholder>
                                    </Placeholder>
                                )
                                )}
                            </div>
                        ) : (
                            <>
                                {/* list contents of the chosen form template */}
                                {formFields.length > 0 && (
                                    <>
                                        {formFields.map((i) => {
                                            // only show fields that are not marked HIDDEN, have a name and are not customfield_14000
                                            if (i.description.toUpperCase() !== 'HIDDEN' && !isEmpty(i.name) && i.field_id.toLowerCase() !== 'customfield_14000') {
                                                
                                                if (i.type === 'any') {
                                                    // radio inputs, only show when options are also available
                                                    if (i.options.length > 0) {
                                                        return (
                                                            <FormGroup id={ i.field_id } key={ i.field_id } className="mb-3">
                                                                <Form.Label>{ i.name }</Form.Label>
                                                                { i.options.map((option) => {
                                                                    return (
                                                                        <FormCheck
                                                                            type='radio'
                                                                            id={ i.field_id }
                                                                            name={ 'option-' + option.object_key }
                                                                            key={ option.object_key }
                                                                            value={ option.object_key }
                                                                            label={ option.label }
                                                                            onChange={ (e) => handleInputs(e) }
                                                                            checked={ inputValues[i.field_id] === option.object_key ? inputValues[i.field_id] : false }
                                                                        />
                                                                    );
                                                                }) }
                                                            </FormGroup>
                                                        );
                                                    }
                                                } else if (i.field_id.toLowerCase() !== 'description') {
                                                    // regular input fields
                                                    return (
                                                        <FormGroup key={i.field_id}>
                                                            <Form.Floating className="mb-3">
                                                                <Form.Control
                                                                    id={i.field_id}
                                                                    type={i.type}
                                                                    required={parseInt(i.required) === 1}
                                                                    placeholder={i.description}
                                                                    onChange={(e) => handleInputs(e)}
                                                                />
                                                                <Form.Label htmlFor={i.field_id}>{i.name}</Form.Label>
                                                                <Form.Control.Feedback type="invalid" />
                                                            </Form.Floating>
                                                        </FormGroup>
                                                    );
                                                } else {
                                                    return (
                                                        <FormGroup key={i.field_id}>
                                                            <Form.Floating>
                                                                <TextInputWithAttachments
                                                                    intl={props.intl}
                                                                    fieldId={i.field_id}
                                                                    fieldName={i.name}
                                                                    required={true}
                                                                    acceptedFileAmount={acceptedFileAmount}
                                                                    attachmentsEnabled={attachmentsEnabled}
                                                                    acceptedFileTypes={acceptedFileTypes}
                                                                    acceptedFileSize={acceptedFileSize}
                                                                    byteConversionFactor={byteConversionFactor}
                                                                    sizeUnit={sizeUnit}
                                                                    handleInputChange={(e) => handleInputs(e)}
                                                                    handleAttachments={(attachments) => handleAttachments(attachments)}
                                                                    history={props.history}
                                                                />
                                                            </Form.Floating>
                                                        </FormGroup>
                                                    );
                                                }
                                            }
                                        })}
                                    </>
                                )}
                            </>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="message-center-modal-form-send" form="message-center-form" variant="primary" type="submit">
                        {lng(messages.send)}
                    </Button>
                    <Button id="message-center-modal-form-cancel" onClick={() => handleHide()} variant="tertiary">{lng(messages.cancel)}</Button>
                </Modal.Footer>
            </div>
        </Modal >
    )
};

MessageCenterModal.propTypes = {
    'intl': PropTypes.object.isRequired,
    'show': PropTypes.bool,
    'hide': PropTypes.func,
    'handleSubmit': PropTypes.func,
    'jobid': PropTypes.number,
    'alert': PropTypes.object,
    'history': PropTypes.object,
    'clearMessageCenterModal': PropTypes.bool
};

export default MessageCenterModal;