import React, { useEffect, useState, createRef } from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import { Card, Col, Row } from "react-bootstrap";
import { formatDate, formatTime } from "../../../services/helpers";
import {messages as messageCenterMessages} from "../MessageCenter";

// services
import { getAttachmentData } from "../../../services/messagecenter";

// translations
export const messages = defineMessages({
    subject: {
        id: "Aihe",
        defaultMessage: 'Aihe'
    }
});


const MessageContent = (props) => {
    const lng = props.intl.formatMessage;
    const [conversationHistory, setConversationHistory] = useState([]);
    const scrollMessagesRef = createRef();

    useEffect(() => {
        if (props.attachments && props.attachments.length > 0) {
            // if ticket has attachments, add them to comments and sort by date
            const combinedArray = checkAttachments(props.attachments, props.comments);
            setConversationHistory(combinedArray);
        } else if (props.comments && props.comments.length > 0) {
            // ticket has no attachments, only use comments
            setConversationHistory(props.comments);
        } else {
            // ticket has no comments or attachments
            setConversationHistory([]);
        }

    }, [props.id]);

    useEffect(() => {
        // scroll to the bottom of the message
        if (scrollMessagesRef.current) {
            scrollMessagesRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }

    }, [conversationHistory]);
    
    /**
     * Open the file in a new tab.
     * @param data
     * @param fileType
     */
    const openFileInNewTab = (data, fileType) => {
        // Create a data URL
        const dataUrl = data.data;  // Open the data URL in a new tab
        const newTab = window.open();
        if (newTab) {
            newTab.document.body.innerHTML = fileType.startsWith('image')
                ? `<img src="${ dataUrl }" alt="file" />`
                : `<iframe src="${ dataUrl }" width="100%" height="100%"></iframe>`;
        }
    }
    
    /**
     * Check attachments, combine them to comments and sort by date to show them in the correct order.
     *
     * @param {*} attachments
     * @param {*} comments
     * @returns Array - combined array of comments and attachments
     */
    function checkAttachments(attachments, comments = []) {
        let allAttachments = [];

        attachments.forEach((attachment, index) => {
            // add fields for easier handling on the UI
            attachment.sent_date = attachment.created;
            attachment.type = 'attachment';

            allAttachments.push(attachment);
        });

        // combine comments and attachments for sorting
        let combinedArray = comments.concat(allAttachments);

        // sort attachments by date
        combinedArray.sort((a, b) => {
            return new Date(a.sent_date) - new Date(b.sent_date);
        });

        // return combined array of comments and attachments
        return combinedArray;
    }
    
    /**
     * Process attachment ids and return buttons for each attachment.
     * @param attachmentIds
     * @param messageId
     * @returns {*}
     */
    function processAttachmentIds(attachmentIds, messageId) {
        // filter out attachments
        const matchedAttachments = attachmentIds.map(attachmentId => {
            const attachment = props.attachments.find(att => att.id === attachmentId);
            return attachment ? { id: attachmentId, filename: attachment.filename, mimetype: attachment.mimetype } : null;
        }).filter(att => att !== null);
        
        return matchedAttachments.map((attachment) => (
            <button key={attachment.id} className={'actionlist-button btn btn-tertiary-sidemenu'} onClick={() => getAttachment(attachment.id, messageId, attachment.mimetype)}>
                {attachment.filename}
            </button>
        ));
    }
    
    /**
     * Get attachment data and open it in a new tab.
     * @param attachmentId
     * @param messageId
     * @param mimetype
     */
    function getAttachment(attachmentId, messageId, mimetype) {
        getAttachmentData(attachmentId, messageId)
            .then(response => {
                openFileInNewTab(response, mimetype);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    // authentication failed, redirect back to authentication
                    const alert = {message: lng(messageCenterMessages.authError), type: 'danger'};
                    props.history.push({
                        pathname: "/logout",
                        alert
                    });
                } else {
                    // Pass the alert to MessageCenter.js
                    props.showAlerts({
                        message: lng(messageCenterMessages.errorOccurredTryAgain),
                        type: 'danger'
                    });
                }
            });
    }

    return (
        
        <div className='scrollable-content-message'>
            <Card key={'messagecontent-' + props.id} className='p-2 ps-sm-4 pe-sm-4 pb-0 messagecontent' role='message'>
                <Card.Body>
                    {/* TODO: change author info in classname, use hardcoded author info (customer) before actual author info is available */}
                    <Row className={'message customer'}>
                        <Col xs={12}>
                            <p className='mb-1'>{lng(messages.subject)}: {props.summary ?? ''}</p>
                            <p>{props.description ?? ''}</p>
                            <p className='text-end text-muted fs-6 mb-0'>{props.date !== '' ? formatDate(props.date) : ''} {props.date !== '' ? formatTime(props.date) : ''}</p>
                        </Col>
                    </Row>

                    {/* List all message comments below the original message */}
                    {conversationHistory.length > 0 && (
                        <>
                            {conversationHistory.map((comment, index) => {
                                const sameDate = index > 0 && formatDate(comment.sent_date) === formatDate(conversationHistory[index - 1].sent_date);

                                return (
                                    <div key={'comment-' + comment.id}>
                                        {/**
                                         * Show message comments from the same author on the same day under one date
                                         * TODO: add sameAuthor check when author information available
                                         */}
                                        {sameDate ?
                                            ('') : (
                                                <p className={'text-muted fs-6 mt-4 mb-2' + (comment.author === 'support' ? ' text-end' : ' text-center')}>
                                                    {formatDate(comment.sent_date)}
                                                </p>
                                            )
                                        }
                                        {/* TODO: change author info in classname, use hardcoded author (support) before actual author information is available  */}
                                        <Row key={'comment-' + comment.id} className={'mb-2 message support'} >
                                            <Col xs={12}>
                                                {comment.attachment_ids ? processAttachmentIds(comment.attachment_ids, props.id) : ''}
                                                <p className="mb-2">{comment.type === 'attachment' ? comment.filename : comment.comment}</p>
                                                <p className='text-muted fs-6 mb-1'>{formatTime(comment.sent_date)}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            }
                            )}
                        </>
                    )}
                    <div ref={scrollMessagesRef} />
                </Card.Body>

                <div className='fixed-element-bottom'>
                    <input type='text' className='form-control' placeholder='placeholder input field' />
                </div>
            </Card>
        </div>
    );
};


MessageContent.propTypes = {
    'intl': PropTypes.object,
    'id': PropTypes.string,
    'date': PropTypes.string,
    'summary': PropTypes.string,
    'description': PropTypes.string,
    'comments': PropTypes.array,
    'attachments': PropTypes.array,
    'history': PropTypes.object,
    'showAlerts': PropTypes.func
};

export default injectIntl(MessageContent);